import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { mergeI18nResources } from '@lagoagest-lda/common-frontend-i18n';
import { CommonCoreResources } from '@lagoagest-lda/common-frontend-core';
import App from './App';

//import en from "./locales/en.json";
//import pt from "./locales/pt.json";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

/*export const LcNegociosResources = {
    en: {
        translation: en
    },
    pt: {
        translation: pt
    }
};*/

const resources = mergeI18nResources(CommonCoreResources);

// Initialize i18n
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        debug: true,
        resources,
    });

// Set the application title
document.title = 'LC-Negócios';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
