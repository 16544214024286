import IconLogo from './logo.png';

export const navbarData = {
    backgroundColor: '#fff',
    backgroundTopColor: '#ffffff00',
    logo: IconLogo,
    title: "LC-Negócios",
    languages: ['en', 'pt']
}

export const footerData = {
    address: 'Rua Pedro Nunes, Nº 101, Zona Industrial. 2500-303 Caldas da Rainha',
    email: 'geral@lc-negocios.com',
    //facebookLink: 'https://www.facebook.com/Neutripuro-Lavandaria-Industrial-1832849606963543/',
    googleMapsLink: '//www.google.pt/maps/search/Rua+Pedro+Nunes,+N%C2%BA+101,+Zona+Industrial.+2500-303+Caldas+da+Rainha/@39.426239,-9.1630535,17z/data=!3m1!4b1?hl=pt-PT',
    //instagramLink: 'tbd',
    logo: IconLogo,
    phoneNumber: '262 889 020',
    title: "LC-Negócios",
    //twitterLink: 'tbd'
};
