import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import { PageNotFound } from '@lagoagest-lda/common-frontend-core'

import { footerData } from './data';

import './App.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path="/*" element={<PageNotFound footer={footerData} />} />
            </Routes>
        </Router>
    );
}

export default App;
