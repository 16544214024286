import React from 'react';
import { footerData } from '../data';
import { Footer } from '@lagoagest-lda/common-frontend-core';

const Home = () => {
    return (
        <>
            <header className="App-header">
                <img src={footerData.logo} className="App-logo" alt="logo" />
                <p>Estamos temporáriamente indisponíveis.</p>
                <p>Iremos resolver a situação com a maior brevidade possível!</p>
            </header>
            <section id='contacts'><Footer address={footerData.address} email={footerData.email} facebookLink={footerData.facebookLink}
                googleMapsLink={footerData.googleMapsLink} instagramLink={footerData.instagramLink} logo={footerData.logo}
                phoneNumber={footerData.phoneNumber} title={footerData.title} twitterLink={footerData.twitterLink} /></section>
        </>
    );
};

export default Home;